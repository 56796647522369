<template>
  <div>
    <GridTable />
  </div>
</template>
<script>
import GridTable from "@/views/main/views/user/components/Grid.vue";

export default {
  name: "UserList",
  components: {
    GridTable,
  },
  data() {
    return {
    };
  },
  methods: {},
};
</script>
  
  
  